<template>
  <Popup
    v-if="store.state.isLoginPopupVisible"
    @onClose="onClose"
  >
    <LoginLinks
      v-if="store.state.isLoginPopupVisible"
      :model="model"
    />
    <FavoriteRecipes
      v-if="store.state.isLoginPopupVisible"
      :model="model.favoriteRecipes"
    />
  </Popup>
</template>

<script>
import store from '@/CVI/WebCore/store'
import Popup from '@/CVI/WebCore/components/Navigation/ServiceMenu/ServiceMenuPanel.vue'
import tracker from '@/CVI/WebCore/core-tracker'

export default {
  components: {
    FavoriteRecipes: () => import('@/CVI/WebCore/components/Navigation/ServiceMenu/FavoriteRecipes.vue'),
    Popup,
    LoginLinks: () => import('@/CVI/WebCore/components/Navigation/ServiceMenu/LoginLinks.vue')
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      store
    }
  },
  mounted() {
    window.addEventListener('hashchange', () => {
      const { hash } = window.location

      if (!hash.startsWith('#login')) {
        this.store.state.isLoginPopupVisible = false
      }
    })
  },
  methods: {
    onClose() {
      this.store.state.isLoginPopupVisible = false
      window.location.hash = ''
      tracker.trackSimpleEvent({
        event: 'menuNavigationLoginClose'
      })
    }
  }
}

</script>
