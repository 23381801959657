<template>
  <div
    class="c-service-menu-popup u-hidden-print"
    :class="{
      'is-opened': isOpened
    }"
  >
    <div
      ref="container"
      v-on-clickaway="close"
      class="c-service-menu-popup__container"
    >
      <div class="c-service-menu-popup__container-inner u-flex u-flex-direction-column u-flex-shrink-0">
        <div class="u-flex u-flex-shrink-0">
          <CloseButton
            class="u-ml--a"
            @click="close"
          />
        </div>
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'
import { disablePageScroll, enablePageScroll } from 'scroll-lock'
import CloseButton from '@/CVI/WebCore/components/CloseButton.vue'

const animationTime = 300

export default {
  components: {
    CloseButton
  },
  mixins: [clickaway],
  props: {
    disablePageScroll: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isOpened: false
    }
  },
  mounted() {
    setTimeout(() => {
      this.isOpened = true
      if (this.disablePageScroll) {
        disablePageScroll(this.$refs.container)
      }
    }, 50)
  },
  beforeDestroy() {
    if (this.isOpened) {
      enablePageScroll(this.$refs.container)
    }
  },
  methods: {
    close() {
      this.isOpened = false
      setTimeout(() => {
        if (this.disablePageScroll) {
          enablePageScroll(this.$refs.container)
        }
        this.$emit('onClose')
      }, animationTime)
    }
  }
}

</script>
